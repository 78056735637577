.poll_and_survey_main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px;
    gap: 24px;
    min-height: 500px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    padding-bottom: 40;
    /* background-color: aqua; */
}

.main-poll-survey {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 32px;
    gap: 24px;
    min-height: 500px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    /* background-color: aqua; */
}

.poll_and_survey_child_box_main_detail_box {
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
    padding-right: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-left: 30px;

}

.react-datepicker__triangle {
    left: -20px !important;
}

.category-flex-row {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: 10px;
}

.date-option-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 152px;
    height: 24px;
}

.category-date-div {
    color: #000000;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
}

.filter-date-range {
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    width: 100%;
}

.filter-date-range button {
    color: #781D7E;
    background-color: #FFFFFF;
    border: none;
}

.poll_and_survey_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px 0px;
    gap: 24px;
    width: 100%;
    background: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;

}


.poll_and_survey_addheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    width: calc(100vw - 300px);
    height: 48px;
    /* background-color: green; */
}


.poll_and_survey_steps {
    width: 50%;
    left: 25%;
    /* color: #CCCCCC; */
    display: -webkit-box;
    line-clamp: 2;
    /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


.poll_and_survey_main_box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}


.poll_and_survey_date_layout {
    display: flex;
    gap: 10px;
    width: 100%;
}


.poll_and_survey_tab_content {
    padding: 20px 0px;
}



.poll_and_survey_scroll_box {
    width: 100%;
    overflow: scroll;
    padding: 0px 20px;
}

.poll_and_survey_scroll_box_detail {
    overflow: scroll;
    padding: 0px 20px;
}


.poll-progress-steps-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 32px;
    gap: 24px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.poll_and_survey_add_row_button {
    display: flex;
    align-items: center;
    color: #F04A00;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
}

.poll_and_survey_items_list {
    min-height: 300px;
    margin-right: 20px;
}


.poll_and_survey_create_item_main_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F6F6F6;
    padding-top: 10px;
    margin-bottom: 20px;
}

.poll_and_survey_create_item_main_box_title {
    margin-bottom: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    padding-left: 10px;
    text-align: left;
    color: black;
}

.chart-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.bar {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: 30px;
    background-color: #e0e0e0;
    /* Light gray background for the bars */
    border-radius: 5px;
}

.bar-content {
    height: 100%;
    background-color: #FF6B6B;
    /* Red color for the bars */
    border-radius: 5px 0 0 5px;
}

.bar-label {
    position: absolute;
    right: 10px;
    /* Position the text to the right of the bar */
    font-size: 14px;
    font-weight: bold;
    color: #000;
    /* Black color for the text */
}



.poll_and_survey_open_ended {
    margin-bottom: 10px;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    outline: none;
}

.poll_and_survey_create_item_main_box_field {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.react-datepicker-wrapper {
    width: 100%;
}


.required-text {
    margin-bottom: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    padding-right: 10px;
    text-align: left;
    color: #73007D;
}


.poll_and_survey_create_item_main_box_title_review {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    padding-left: 10px;
    text-align: left;
    color: black;
}

.review-text-field {
    margin-left: 50px;
    width: 95% !important;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}


.poll_and_survey_dropdown {
    height: 45px;
    background-color: white;
}

.poll_and_survey_create_item_main_box_title_span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #666a6c;
}

.poll_and_survey_create_item_row {
    display: flex;
    gap: 10px;
    background-color: white;
    margin-left: 1px;
    margin-right: 1px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;


}

.poll_and_survey_footer_action {
    display: flex;
    align-content: center;
    align-items: center;
    padding: 10px;
    gap: 15px;
    justify-content: flex-end;
}

.poll_and_survey_footer_action_icon {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.poll_and_survey_footer_action_icon:hover {
    width: 22px;
    height: 22px;
}

.poll_and_survey_main_box_field {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}


.poll_and_survey_add_option_main {
    margin-top: 10px;
    background-color: white;
    border-radius: 10px;
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    margin-left: 20px;
    margin-right: 55px;
    padding-left: 20px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    /* Ensure border is visible */
    color: #424242;
    gap: 5px;
}

.poll_and_survey_add_option_list {
    background-color: #FFFFFF;
    padding: 10px;
    margin-left: 1px;
    margin-right: 1px;
}

.or_text {
    justify-content: center;
    align-self: center;
    color: #666666;
    font-size: 15px;
}


.poll-survey-option-item {
    padding-top: 10px;
    padding-left: 20px;
    gap: 10px;
    display: flex;
    align-items: center;
}

.delete-button {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    border: 1px solid #73007D;
    /* Ensure border is visible */
    color: #73007D;
    cursor: pointer;
}

.delete-button-icon {
    fill: #73007D;

}


.delete-button-icon:hover {
    fill: white;
}



.poll_survey_child_box_main_detail_items {
    display: flex;
    gap: 20px;
    /* min-height: 55px; */
}

.poll_survey_child_box_main_detail_items_title {
    width: 230px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    color: #333333;
}



.poll_survey_detail_padding_bottom {
    padding-bottom: 2px;
    padding-top: 2px;
}

.poll_survey_child_box_main_detail_items_splitter {
    width: 1px;
    background: #CCCCCC;
}

.poll_survey_child_box_main_detail_items_subdetail {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #333333;
}


.department-container {
    display: flex;
    gap: 16px;
}

.department-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    width: 150px;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.department-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.selected-card {
    background-color: #5e2689;
    color: white;
}

.selected-card:hover {
    background-color: #4b1f6f;
    /* Darkens the background on hover */
}

.department-icon {
    width: 32px;
    height: 32px;
    margin-bottom: 8px;
}

.department-count {
    font-size: 24px;
    font-weight: bold;
}

.department-total {
    font-size: 16px;
}

.department-name {
    font-size: 14px;
    margin-top: 4px;
}


.chart-container {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 20px auto;
  }
  
  h3 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .question-text {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .bar-chart {
    width: 100%;
    position: relative;
  }
  
  .bar-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    position: relative;
  }
  
  .bar-label {
    width: 100px;
    font-size: 14px;
    text-align: right;
    margin-right: 10px;
  }
  
  .bar-wrapper {
    flex-grow: 1;
    height: 20px;
    background: #f0f0f0;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .bar {
    height: 100%;
    border-radius: 5px;
  }
  
  .bar-value {
    font-size: 14px;
    margin-left: 5px;
    position: absolute;
    right: -40px;
  }
  
  .divider-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 10px;
    border-top: 1px solid #ddd;
  }
  
  .divider {
    position: relative;
    flex: 1;
    text-align: center;
  }
  
  .grid-value {
    font-size: 12px;
    color: #555;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
  }
  