/* Ensure table uses table layout */
table {
    width: 100%;
    border-collapse: collapse;
    /* Ensure no spacing between cells */
}

th, td {
    border: 0.7px solid #d9dfeb !important; /* Add borders for clarity */
    padding: 8px;
    text-align: center;
  }

th {
    background-color: #f4f4f4;
}

thead {
    background-color: #F6F6F6;
}

.runtime_feedback_report_table_header {
    background-color: #F6F6F6;
    font-weight:400 !important;
    font-size: 15px !important;
    padding: 10px;
}


.runtime_feedback_report_table_body {
    background-color: #FFFFFF;
    font-weight:300 !important;
    font-size: 15px !important;
    padding: 10px;
}


.runtime_feedback_report_table_footer {
    font-weight:bold !important;
    font-size: 15px !important;
    padding: 10px;
}

.runtime_feedback_report_table_footer_parent {
    background-color: #f0f0f0;
    height: 50px;
}

.runtime_feedback_report_hyper_link{
    color: #73007D
}


.TableHandle:hover {
    background-color: #fafafa;
}


.runtime_feedback_report_child_box_main_detail_box {
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
    padding-right: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-left: 30px;

}

.react-datepicker__triangle {
    left: -20px !important;
}

.category-flex-row {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: 10px;
}

.date-option-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 152px;
    height: 24px;
}

.category-date-div {
    color: #000000;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
}

.filter-date-range {
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    width: 100%;
}

.filter-date-range button {
    color: #781D7E;
    background-color: #FFFFFF;
    border: none;
}