:root {
  --extra-small-font-size: 0.8rem;
  --small-font-size: 1rem;
  --medium-font-size: 1.3rem;
  --box-title-font-size: 1.2rem;
  --icon-size: 2rem;
}
/* .body{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
} */

.filter-popup > .k-window {
  position: absolute !important;
  right: 0;
}

.dashboard_main_box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 75px);
  gap: 30px;
  flex-direction: column;
}

.dashboard_title_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dashboard_main_text {
  font-family: Poppins, sans-serif;
  font-size: var(--medium-font-size);
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.dashboard_filter_dropdown_item {
  font-size: 12px;
}

.dashboard_filter_dropdown_item:focus {
  box-shadow: none;
}

.dashboard_filter_dropdown_item
  .k-input-button
  .k-icon.k-i-caret-alt-down:before {
  content: none !important;
}

.dashboard_filter_dropdown_item .k-input-button .k-icon.k-svg-i-caret-alt-down {
  background-image: url("/src/assets/common/arrowDown.svg") !important;
  background-repeat: no-repeat !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-position: -5px center;
}

.dashboard_filter_dropdown_item
  .k-input-button
  .k-icon.k-svg-i-caret-alt-down
  svg {
  display: none;
}

.dashboard_filter_dropdown {
  flex: 1 1;
}

.dashboard_filter_dropdown span {
  border-color: #73007d;
  height: 48px;
  background: white;
  border-radius: 8px;
  color: #73007d;
  font-size: 14px;
}

.dashboard_filter_dropdown span:hover {
  background: white;
}

.dashboard_filter_dropdown .k-input-value-text {
  display: flex;
  align-items: center;
}

.dashboard_toolbar_main_box {
  display: flex;
  align-items: center;
  margin: 0px 20px;
  height: 100px;
  justify-content: space-between;
}

.dashboard_toolbar_title {
  font-family: Poppins;
  font-weight: 600;
  font-size: 32px;
  line-height: 36.48px;
  letter-spacing: 0%;
  color: #333333;
}

.dashboard_toolbar_action_box {
  display: flex;
  gap: 10px;
  width: 50%;
}

.dashboard_item_sub_box {
  background: #f3f4f6;
  padding: 20px;
  height: calc(100vh - 215px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dashboard_graph_title_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_graph_title {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--medium-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  color: #333333;
  width: 85%;
}

.dashboard_communication_title_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_communication_title {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--medium-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  color: #333333;
  width: 85%;
}

.dashboard_comm_reaction_main_box {
  display: flex;
  gap: 15px;
}

.dashboard_comm_reaction_first_box {
  flex: 1 1 65%;
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px; */
}

.dashboard_comm_reaction_second_box {
  flex: 1 1 35%;
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
}

.dashboard_comm_reaction_second_box_child_box {
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
}

.dashboard_comm_reaction_first_box_item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  min-width: 90px;
}

.dashboard_comm_reaction_first_box_item_text {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  text-align: center;
  color: #333333;
}

.dashboard_comm_reaction_first_box_item_count {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--medium-font-size);
  line-height: 36.48px;
  letter-spacing: 0%;
  text-align: center;
  color: #73007d;
}

.dashboard_comm_reaction_second_box_item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  min-width: 90px;
}

.dashboard_comm_reaction_second_box_item_count {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--medium-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  text-align: center;
  color: #73007d;
}

.dashboard_comm_reaction_second_box_item_text {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 36.48px;
  letter-spacing: 0%;
  text-align: center;
  color: #73007d;
}

.dashboard_comm_reaction_second_box_item_title {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--box-title-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  color: #333333;
  margin-bottom: 20px;
}

.barChartLegend {
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 20.8px;
  margin-left: 10px;
  margin-right: 10px;
}

.dashboard_barChart_legend_main_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_barChart_legend_title {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--box-title-font-size);
  line-height: 30px;
  color: #333333;
}

.dashboard_barChart_legend_box {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.dashboard_barChart_legend_box_item {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 20.8px;
  color: #666666;
}

.dashboard_barChart_legend_box_item_dot {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.dashboard_comm_chart_main_box {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dashboard_comm_chart_first_box {
  height: 400px;
  background: white;
  margin-top: 20px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  flex: 1 1 65%;
  padding: 20px;
}

.dashboard_comm_chart_second_box {
  height: 400px;
  background: white;
  margin-top: 20px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  flex: 1 1 35%;
  padding: 20px;
}

.dashboard_comm_chart_second_box_title {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--box-title-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  /* margin: 20px; */
  color: #333333;
}

.dashboard_pieChart_legend_main_box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 30px 20px;
  height: 100%;
  padding: 0px 20px;
  overflow: scroll;
  max-height: 300px;
}

.dashboard_pieChart_legend_box_item_dot {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.dashboard_pieChart_legend_box_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_pieChart_legend_box_item_child {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard_comm_chart_legent_text {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 20.8px;
  letter-spacing: 0%;
  color: #666666;
}

.dashboard_happening_detail_main_box {
  display: flex;
  gap: 15px;
}

.dashboard_happening_detail_first_box {
  flex: 1 1 50%;
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px; */
}

.dashboard_happening_detail_second_box {
  flex: 1 1 50%;
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
}

.dashboard_happening_detail_first_box_item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  min-width: 90px;
}

.dashboard_happening_detail_first_box_item_text {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  text-align: center;
  color: #333333;
}

.dashboard_happening_detail_first_box_item_count {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--medium-font-size);
  line-height: 36.48px;
  letter-spacing: 0%;
  text-align: center;
  color: #73007d;
}

.dashboard_happening_detail_active_promotion_main_box {
  margin-top: 20px;
  border: 1px solid #cccccc;
  background: white;
  padding: 20px;
  border-radius: 8px;
}

.dashboard_happening_see_all_button {
  font-family: Poppins;
  font-weight: 500;
  font-size: var(--small-font-size);
  line-height: 22.4px;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: Auto;
  color: #73007d;
  cursor: pointer;
  white-space: nowrap;
}

.dashboard_happening_table_title {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--box-title-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  color: #333333;
}

.dashboard_usermanagement_first_box_first_child {
  flex: 1 1 50%;
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
  height: 300px;
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px; */
}

.dashboard_usermanagement_first_box_first_child_item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  min-width: 90px;
}

.dashboard_usermanagement_first_box_first_child_item_text {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  text-align: center;
  color: #333333;
}

.dashboard_usermanagement_first_box_first_child_item_count {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--medium-font-size);
  line-height: 36.48px;
  letter-spacing: 0%;
  text-align: center;
  color: #73007d;
}

.dashboard_usermanagement_chart_first_box {
  height: 300px;
  background: white;
  margin-top: 20px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
}

.dashboard_usermanagement_main_box {
  display: flex;
  gap: 15px;
}

.dashboard_usermanagement_see_all_button {
  font-family: Poppins;
  font-weight: 500;
  font-size: var(--small-font-size);
  line-height: 22.4px;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: Auto;
  color: #73007d;
  cursor: pointer;
  white-space: nowrap;
}

.dashboard_usermanagement_second_box_title {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--box-title-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  color: #333333;
}

.dashboard_usermanagement_child_second_box {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
}

.dashboard_usermanagement_child_second_item_box {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 20.8px;
  letter-spacing: 0%;
  color: #666666;
  /* justify-content: space-between; */
  gap: 30px;
  height: calc(100% - 55px);
}

.dashboard_usermanagement_child_second_item_child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_usermanagement_child_second_item_child_title_box {
  display: flex;
  gap: 10px;
  align-items: center;
}
.dashboard_group_first_main_box {
  display: flex;
  gap: 15px;
}

.dashboard_group_first_box_second_child {
  flex: 1 1 80%;
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  height: 300px;
}

.dashboard_group_first_box_first_child {
  flex: 1 1 20%;
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
  height: 300px;
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px; */
}

.dashboard_group_first_box_first_child_item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  min-width: 90px;
}

.dashboard_group_first_box_first_child_item_text {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  text-align: center;
  color: #333333;
}

.dashboard_group_first_box_first_child_item_count {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--medium-font-size);
  line-height: 36.48px;
  letter-spacing: 0%;
  text-align: center;
  color: #73007d;
}

.dashboard_dr_main_box {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.dashboard_dr_first_box {
  flex: 1 1 70%;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.dashboard_dr_second_box {
  flex: 1 1 30%;
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  min-height: 400px;
}

.dashboard_dr_first_box_first_child {
  display: flex;
  gap: 15px;
  height: 50%;
}

.dashboard_dr_first_box_second_child {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  height: 50%;
}

.dashboard_dr_first_box_first_child_second_item_box {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  flex: 1 1 70%;
}

.dashboard_dr_first_box_first_child_second_item_box_see_all_button {
  font-family: Poppins;
  font-weight: 500;
  font-size: var(--small-font-size);
  line-height: 22.4px;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: Auto;
  color: #73007d;
  cursor: pointer;
  white-space: nowrap;
}

.dashboard_dr_first_box_first_child_first_item_box {
  flex: 1 1 30%;
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px; */
}

.dashboard_dr_first_box_first_child_first_item_box_detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  min-width: 90px;
}

.dashboard_dr_first_box_first_child_first_item_box_detail_text {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  text-align: center;
  color: #333333;
}

.dashboard_dr_first_box_first_child_first_item_box_detail_count {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--medium-font-size);
  line-height: 36.48px;
  letter-spacing: 0%;
  text-align: center;
  color: #73007d;
}

.dashboard_dr_second_box_title {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--box-title-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  color: #333333;
}

.dashboard_dr_first_box_first_child_second_item_main_box {
  columns: 2;
  margin-top: 20px;
  column-rule: solid;
  column-rule-color: #cccccc;
  column-gap: 40px;
}

.dashboard_dr_first_box_first_child_second_item_box_child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 10px;
}

.dashboard_dr_first_box_first_child_second_item_box_child_title {
  display: flex;
  gap: 10px;
  align-items: start;
}

.dahsboard_ifeedback_main_box {
  display: flex;
  margin-top: 20px;
  gap: 15px;
}

.dahsboard_ifeedback_first_box {
  display: flex;
  flex-direction: column;
  flex: 1 1 65%;
  gap: 15px;
}

.dahsboard_ifeedback_second_box {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  flex: 1 1 35%;
}

.dahsboard_ifeedback_first_box_first_child {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
}

.dahsboard_ifeedback_first_box_last_child {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  height: 300px;
}

.dashboard_ifeedback_first_box_first_child_detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  min-width: 90px;
}

.dashboard_ifeedback_first_box_first_child_detail_count {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--medium-font-size);
  line-height: 36.48px;
  letter-spacing: 0%;
  text-align: center;
  color: #73007d;
}

.dashboard_ifeedback_first_box_first_child_detail_text {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  text-align: center;
  color: #333333;
}

.dahsboard_ifeedback_second_box_title {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--box-title-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  color: #333333;
}

.dashboard_ifeedback_see_all_button {
  font-family: Poppins;
  font-weight: 500;
  font-size: var(--small-font-size);
  line-height: 22.4px;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: Auto;
  color: #73007d;
  cursor: pointer;
  white-space: nowrap;
}

.dashboard_ifeedback_second_item_box_child {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 20.8px;
  letter-spacing: 0%;
  color: #666666;
  /* justify-content: space-between; */
  gap: 30px;
  height: calc(100% - 55px);
}

.dashboard_ifeedback_second_item_box_child_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_ifeedback_second_item_box_child_item_detail {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dahsboard_drsecure_main_box {
  display: flex;
  margin-top: 20px;
  gap: 15px;
}

.dahsboard_drsecure_first_box {
  display: flex;
  flex-direction: column;
  flex: 1 1 65%;
  gap: 15px;
}

.dahsboard_drsecure_second_box {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  flex: 1 1 35%;
}

.dahsboard_drsecure_first_box_first_child {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
}

.dahsboard_drsecure_first_box_last_child {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  height: 300px;
}

.dashboard_drsecure_first_box_first_child_detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  min-width: 90px;
}

.dashboard_drsecure_first_box_first_child_detail_count {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--medium-font-size);
  line-height: 36.48px;
  letter-spacing: 0%;
  text-align: center;
  color: #73007d;
}

.dashboard_drsecure_first_box_first_child_detail_text {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  text-align: center;
  color: #333333;
}

.dahsboard_drsecure_second_box_title {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--box-title-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  color: #333333;
}

.dashboard_drsecure_see_all_button {
  font-family: Poppins;
  font-weight: 500;
  font-size: var(--small-font-size);
  line-height: 22.4px;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: Auto;
  color: #73007d;
  cursor: pointer;
  white-space: nowrap;
}

.dashboard_drsecure_second_item_box_child {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 20.8px;
  letter-spacing: 0%;
  color: #666666;
  /* justify-content: space-between; */
  gap: 30px;
  height: calc(100% - 55px);
}

.dashboard_drsecure_second_item_box_child_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_drsecure_second_item_box_child_item_detail {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dahsboard_MIC_main_box {
  display: flex;
  margin-top: 20px;
  gap: 15px;
}

.dahsboard_MIC_first_box {
  display: flex;
  flex-direction: column;
  flex: 1 1 65%;
  gap: 15px;
}

.dahsboard_MIC_second_box {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  flex: 1 1 35%;
}

.dahsboard_MIC_first_box_first_child {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
}

.dahsboard_MIC_first_box_last_child {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  height: 300px;
}

.dashboard_MIC_first_box_first_child_detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  min-width: 90px;
}

.dashboard_MIC_first_box_first_child_detail_count {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--medium-font-size);
  line-height: 36.48px;
  letter-spacing: 0%;
  text-align: center;
  color: #73007d;
}

.dashboard_MIC_first_box_first_child_detail_text {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  text-align: center;
  color: #333333;
}

.dahsboard_MIC_second_box_title {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--box-title-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  color: #333333;
}

.dashboard_MIC_see_all_button {
  font-family: Poppins;
  font-weight: 500;
  font-size: var(--small-font-size);
  line-height: 22.4px;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: Auto;
  color: #73007d;
  cursor: pointer;
  white-space: nowrap;
}

.dashboard_MIC_second_item_box_child {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 20.8px;
  letter-spacing: 0%;
  color: #666666;
  /* justify-content: space-between; */
  gap: 30px;
  height: calc(100% - 55px);
}

.dashboard_MIC_second_item_box_child_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_MIC_second_item_box_child_item_detail {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dahsboard_care_main_box {
  display: flex;
  margin-top: 20px;
  gap: 15px;
}

.dahsboard_care_first_box {
  display: flex;
  flex-direction: column;
  flex: 1 1 65%;
  gap: 15px;
}

.dahsboard_care_second_box {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  flex: 1 1 35%;
  min-height: 400px;
}

.dahsboard_care_first_box_first_child {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
}

.dahsboard_care_first_box_last_child {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  min-height: 210px;
}

.dashboard_care_first_box_first_child_detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  min-width: 90px;
}

.dashboard_care_first_box_first_child_detail_count {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--medium-font-size);
  line-height: 36.48px;
  letter-spacing: 0%;
  text-align: center;
  color: #73007d;
}

.dashboard_care_first_box_first_child_detail_text {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  text-align: center;
  color: #333333;
}

.dahsboard_care_second_box_title {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--box-title-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  color: #333333;
}

.dashboard_care_see_all_button {
  font-family: Poppins;
  font-weight: 500;
  font-size: var(--small-font-size);
  line-height: 22.4px;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: Auto;
  color: #73007d;
  cursor: pointer;
  white-space: nowrap;
}

.dashboard_care_second_item_box_child {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 20.8px;
  letter-spacing: 0%;
  color: #666666;
  /* justify-content: space-between; */
  height: calc(100% - 55px);
  gap: 30px;
}

.dashboard_care_second_item_box_child_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_care_second_item_box_child_item_detail {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dashboard_care_chart_second_box_title {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--box-title-font-size);
  line-height: 30px;
  letter-spacing: 0%;
  /* margin: 20px; */
  color: #333333;
}

.barchart_tick_text {
  font-family: Poppins;
  font-weight: 600;
  font-size: 11px;
  line-height: 16.5px;
  letter-spacing: 3%;
  text-align: center;
}

.barchart_tooltip_main_box {
  background-color: white;
  border: 1px solid #66666672;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.barchart_tooltip_text {
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--extra-small-font-size);
  line-height: 20.8px;
  color: #666666;
}

.dashboard_graph_see_all_button {
  font-family: Poppins;
  font-weight: 500;
  font-size: var(--small-font-size);
  line-height: 22.4px;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: Auto;
  color: #73007d;
  cursor: pointer;
  white-space: nowrap;
}

.dashboard_no_record {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  gap: 20px;
  height: 100%;
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 20.8px;
  color: #666666;
  text-align: center;
}

.dashboard_upcoming {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  color: #666666;
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 20.8px;
  min-height: 250px;
}

.dashboard_no_permission {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 250px; */
  gap: 20px;
  margin-top: 20px;
  height: 300px;
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: 20.8px;
  color: #666666;
  text-align: center;
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
}

.dashboard_item_sub_box img{
  width: var(--icon-size);
}

@media (min-width: 2560px) {
  :root {
    --extra-small-font-size: 1.2rem;
    --small-font-size: 1.4rem;
    --medium-font-size: 1.6rem;
    --box-title-font-size: 1.7rem;
    --icon-size: 4rem;
  }
}

@media (min-width: 1440px) {
  :root {
    --extra-small-font-size: 0.8rem;
    --small-font-size: 1.1rem;
    --medium-font-size: 1.3rem;
    --box-title-font-size: 1.4rem;
    --icon-size: 2.5rem;
  }
}

@media (min-width: 1024px) {
  :root {
    --extra-small-font-size: 0.8rem;
    --small-font-size: 1rem;
    --medium-font-size: 1.2rem;
    --box-title-font-size: 1.3rem;
    --icon-size: 2rem;
  }
}

.dashboard_filter_dropdown .k-input-button{
  background: white !important;
}