

.poll_and_survey_main_box_title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    color: black;
}

.poll_and_survey_main_box_field {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}


.poll_and_survey_main_text_area_box_field {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    min-height: 100px;
    box-sizing: border-box;
}

.poll_and_survey_main_box_title_span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #666a6c;
}



.poll_and_survey_error_text {
    margin-top: 10px;
    font-style: italic;
    color: red;
    font-size: 12px;
    min-height: 20px;
}
