.reusable_header_main_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 30px;
}

.reusable_header_title {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 36.48px;
  text-align: left;
  color: #333333;
}

.reusable_header_icon_main_box {
  display: flex;
  gap: 10px;
}

.reusable_header_create_box {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-align: center;
  color: #73007d;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #73007d;
  border-radius: 10px;
  padding: 0px 20px;
  cursor: pointer;
}

.reusable_header_icon_box {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #73007d;
  border-radius: 10px;
  height: 46px;
  padding: 0px 14px;
  cursor: pointer;
}

.reusable_table_button_dropdown {
  font-size: 15px !important;
  height: 48px !important;
  color: #333333 !important;
  text-align: center !important;
  border-radius: 8px !important;
  border: 1px solid #333333 !important;
  background: white !important;
}

.reusable_table_button_dropdown:active,
.reusable_table_button_dropdown:focus-within {
  background-color: #f04a00 !important;
  color: white !important;
  border-color: #f04a00 !important;
}

.reusable_table_pagination_main_box {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* width: calc(100% - 240px); */
  background-color: #f6f6f6;
}

.Table_header {
  gap: 10px;
  display: flex;
  align-items: center;
  height: 40px;
}

.TableHandle {
  height: 60px;
  background: white;
  cursor: pointer;
}

.TableHandle:hover {
  background-color: #fafafa;
}

.Table_button {
  background: #eb7930;
  border: 1px solid #eb7930;
  border-radius: 100px;
  height: 48px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  justify-content: center;
  max-width: 155px;
  cursor: pointer;
}

.table-select-custom table {
  width: 100%;
  text-align: left;
  border: 0;
  background: #efefef;
  border-collapse: collapse;
}

.table-select-custom th,
.table-select-custom td {
  padding: 0px 16px;
  border-width: 0px 1px;
}

.table-select-custom th {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-align: left;
}

.table-select-custom td {
  max-width: 100px;
  overflow: hidden;
  font-family: Poppins;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #776f6a;
}

#table {
  max-width: calc(100vw - 260px);
  overflow-x: auto;
}

/* width */
#table::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#table::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
#table::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 5px;
}

/* Handle on hover */
#table::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.scrolledDIV::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scrolledDIV::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 5px;
}

/* Handle */
.scrolledDIV::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 5px;
}

/* Handle on hover */
.scrolledDIV::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.Dialog_cross_button {
  position: absolute;
  right: 0px;
  top: 0;
  cursor: pointer;
}

.Dialog_main_box {
  margin: 20px;
  width: 350px;
}

.Dialog_detail_box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.Dialog_detail_title {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.Dialog_detail_subTitle {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.Dialog_button_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  gap: 20px;
}

.Dialog_button_cancel {
  flex: 1;
  color: #f04a00;
  border: 1px solid #f04a00;
  padding: 15px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Dialog_button_confirm {
  flex: 1;
  color: white;
  border: 1px solid #f04a00;
  padding: 15px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #f04a00;
}

.search-div {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin: 0px 5px 10px;
}

.search-div .textbox {
  border: 0px !important;
  border-radius: 0;
  height: 48px;
  flex-grow: 1;
  font-family: Poppins;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.search-div .textbox:focus {
  outline-width: 0;
}

.Search_icon {
  margin-left: 10px;
  margin-right: 10px;
}

.Search-reset-icon {
  cursor: pointer;
}

.No_record {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 2px);
  position: absolute;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
}

.Permission_Denined_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Dialog_detail_subTitle_content {
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 20.8px;
  margin-bottom: 10px;
}

.custom_dropdown_main_box {
  position: relative;
  width: 100%;
  height: 46px;
}

.custom_dropdown_child_box {
  border: 1px solid #73007d;
  background: white;
  color: #73007d;
  font-family: Poppins;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 8px;
  justify-content: space-around;
  cursor: pointer;
}

.custom_dropdown_items_box {
  position: absolute;
  background: white;
  display: flex;
  flex-direction: column;
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  border: 1px solid #d6d6d6;
  left: -1px;
  font-family: Poppins;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  box-shadow: 4px 8px 8px 4px #0000001a;
  z-index: 100;
  cursor: pointer;
}

#box_transaction {
  opacity: 0.2;
  transition: ease-in-out 0.4s;
  height: 0px;
  padding: 0px;
}

#box_transaction.is-active {
  height: 150px;
  opacity: 1;
  /* padding: 10px 10px; */
}

.custom_dropdown_items_box {
  opacity: 0.2;
  transition: ease-in-out 0.4s;
  height: 0px;
  padding: 0px;
}

.custom_dropdown_items_box.is-active {
  height: 150px;
  opacity: 1;
  /* padding: 10px 10px; */
}

.custom_dropdown_item {
  min-height: 45px;
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
  padding: 10px 10px;
  gap: 15px;
}

.custom_dropdown_item:hover {
  background-color: #73007d;
  color: white;
}

.custom_dropdown_check_icon {
  width: 15px;
  height: 15px;
  color: #73007d;
  background: white;
  border-radius: 10px;
  font-size: 11px;
}

.custom_dropdown_item:hover .icon_background {
  background-color: transparent;
  color: white;
}
